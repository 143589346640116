import { ApiFilestackImageData } from 'api/types/ApiFilestackImageData';
import { Box } from 'components/Box/Box';
import { Hero } from 'components/Hero/Hero';
import { H1 } from 'components/Text/H1';
import { RichText } from 'components/Text/RichText';
import { PostSearchInputContainer } from 'containers/PostSearchInputContainer';
import { CmsApiMiniPage } from 'modules/cms/api/types/CmsApiMiniPage';
import { CmsApiPage } from 'modules/cms/api/types/CmsApiPage';

import {
  ArticleSearchHeroContainer,
  ArticleSearchHeroTitle,
  ArticleSearchHeroWrapper,
} from './ArticleSearchHero.styled';

type Props = {
  content: CmsApiPage;
  title?: string;
  image: ApiFilestackImageData | null | undefined;
  imageMobile: ApiFilestackImageData | null | undefined;
};

export function ArticleSearchHero({
  content,
  title,
  image,
  imageMobile,
}: Props) {
  const { subsite } = content;

  // search is only available when there's a home page
  const homePage = subsite.homePage as CmsApiMiniPage;
  return (
    <Hero
      py={[40, null, 72, 108]}
      // @ts-expect-error TS(2322): Type 'FilestackImageData | null | undefined' is no... Remove this comment to see the full error message
      heroImage={image}
      minHeight={['244px', null, '323px', '394px']}
      // @ts-expect-error TS(2322): Type 'FilestackImageData | null | undefined' is no... Remove this comment to see the full error message
      mobileHeroImage={imageMobile}
      data-qa-id="article-search-hero"
    >
      <ArticleSearchHeroContainer>
        <ArticleSearchHeroWrapper>
          <Box mb={24}>
            <RichText>
              <H1>
                <ArticleSearchHeroTitle
                  style={{ lineHeight: 1.3 }}
                  dangerouslySetInnerHTML={{
                    // @ts-expect-error TS(2769): No overload matches this call.
                    __html: title?.replace(/<(\/?)(p|h1)>/g, '<$1span>'),
                  }}
                />
              </H1>
            </RichText>
          </Box>
          <PostSearchInputContainer
            homePageUrl={homePage.url}
            searchPlaceholderText={subsite.searchPlaceholderText}
            usageContext="searchHero"
          />
        </ArticleSearchHeroWrapper>
      </ArticleSearchHeroContainer>
    </Hero>
  );
}
